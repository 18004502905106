import Entity from "./Entity";
import DeviceType from "./DeviceType";

export default class extends Entity {
    version = "1.0.0";
    sw_version = "1.0.0";
    model = "avata";
    type = DeviceType.LOCKBOX;
    log_position = 0;
    battery_level = 10;
    ble_id = "";
    rssi = "";
    location = {
        lon: 54646851.23,
        lat: 5464654564.2343,
    };
    ui = {
        color: "#DDD",
        src: "http://aux.iconspalace.com/uploads/12332608241366226568.png",
        title: "Sunday Lane",
        description: "",
        dark: false,
    }
}