<template>
  <div
    style="
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow: hidden;
    "
  >
    <div
      style="
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        overflow: auto;
        padding-bottom: 10em;
      "
    >
      <v-btn
        absolute
        dark
        fab
        right
        color="green darken-2"
        style="bottom: 3%"
        @click="scan()"
      >
        <v-icon>fa-redo</v-icon>
      </v-btn>
      <v-btn
        color="brown"
        absolute
        dark
        fab
        left
        style="bottom: 3%"
        v-show="scanning"
        @click="cancel()"
      >
        <v-icon>fa-ban</v-icon>
      </v-btn>
      <v-row>
        <v-col cols="12">
          <h3 class="mt-3 mb-6 font-weight-light text-center">
            Select a device from the list
          </h3>
          <div align="center" class="mt-10">
            <v-progress-circular
              v-show="items.length == 0"
              class="mt-10"
              :size="50"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
          <v-list-item
            v-show="items.length > 0"
            v-for="item in items"
            :key="item.id"
            @click="choose(item)"
          >
            <v-list-item-avatar>
              <v-icon color="green">fa-wifi</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
              <v-list-item-subtitle
                >{{ item.id }} ({{ item.rssi }})</v-list-item-subtitle
              >
            </v-list-item-content>

            <v-list-item-action>
              <v-btn icon>
                <v-icon x-small>fa-chevron-right</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { v4 as UUID } from "uuid";
import BLE from "../lib/ble";
import Device from "../model/Device";

let ble = undefined;

let body = {
  name: "Scan",
  data: () => ({
    scanning: false,
    items: [],
  }),
  created() {
    ble = new BLE();
    this.scan();
    this.$emit("hideMenu");
  },
  onDiscover(item) {
    this.items.push(item);
  },
  methods: {
    cancel() {
      this.$router.push({ name: "Device" });
    },
    async choose(item) {
      ble.stopScan();
      this.$store.device = undefined;
      let devices = await this.$store.db.devices.get();
      // console.log(item);
      for (let d of devices) {
        if (d.ble_id == item.id) {
          this.$store.device = d;
          this.$router.push({ name: "Settings" });
          return;
        }
      }
      if (!this.$store.device) {
        let d = new Device();
        d.id = UUID();
        d.name = item.name;
        d.ui.title = item.name;
        d.ble_id = item.id;
        d.rssi = item.rssi;
        this.$store.device = d;
      }
      // console.log("Device Set", this.$store.device);
      this.$router.push({ name: "Settings" });
    },
    enableScan() {
      this.scanning = false;
    },
    scan() {
      if (this.scanning) {
        return;
      }
      this.scanning = true;
      //'6e400001-b5A3-f393-e0a9-e50e24dcca9e'
      this.items = [];
      ble.scan([], 1, body.onDiscover.bind(this), null);
      setTimeout(this.enableScan, 1000);
    },
  },
};

export default body;
</script>
